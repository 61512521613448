import { appConfig } from "@/assets/scripts/js/AppConfig";
import { httpClient } from "@/assets/scripts/js/HttpClient";

export default {
  data: () => ({}),
  created() {
    this.linkDownloadStrategies = {
      [appConfig.SCREEN_ID.P_STK_110]: (previousInfo) => {
        // パス情報
        const pathSegments = previousInfo.path.split("/");
        // ローディング画面表示ON
        this.loadingCounter++;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = httpClient.createRequestBodyConfig();
        // 共通IF項目：画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_STK_110;
        // ピッキンググループSIDリストに設定
        body.reqIdv.pickingGrSids = [pathSegments[2]];
        return new Promise((resolve, reject) => {
          httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_PICKING_LIST_PARTS, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
                // 返却されたurlをデータに格納
                resolve(jsonData.resIdv.filePath);
              } else {
                if (!this.errorMessage.isError) {
                  this.errorMessage.isError = true;
                  this.errorMessage.message = jsonData.resCom.resComMessage;
                }
                reject(response);
              }
            })
            .catch((ex) => {
              if (!this.errorMessage.isError) {
                this.errorMessage.isError = true;
                this.errorMessage.message = ex;
              }
              reject(resolve);
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter--;
            });
        });
      },
    };
  },
  mounted() {
    // 前画面の情報
    const previousInfo = this.$route.meta.previous;
    // ダウンロードURLの場合
    if (previousInfo.meta.isDownloadUrl) {
      // パス情報
      const pathSegments = previousInfo.path.split("/");
      this.linkDownloadStrategies[pathSegments[1]](previousInfo).then((response) => {
        // 処理が正常の場合、ダウンロード
        location.href = response;
      });
    }
  },
};
